import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Resume from "./pages/Resume";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import UIUXDesign from "./pages/UIUXDesign";
import FrontEnd from "./pages/FrontEnd";
import GraphicDesign from "./pages/GraphicDesign";
import Video from "./pages/Video";
import Photo from "./pages/Photo";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Sidebar className="fixed-bottom" />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/About" element={<About />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/UIUXDesign" element={<UIUXDesign />} />
          <Route path="/FrontEnd" element={<FrontEnd />} />
          <Route path="/GraphicDesign" element={<GraphicDesign />} />
          <Route path="/Video" element={<Video />} />
          <Route path="/Photo" element={<Photo />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
