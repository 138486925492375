import "../pages/homepage.css";

const Contact = () => {
  return (
    <div class="mainContainer">
      <h1>Contact Me</h1>
      <hr />
      <p>
        <b>Hours:</b> 8am to 8pm EST
      </p>
      <p>
        <b>Phone:</b> 954-709-7709
      </p>
      <p>
        <b>Email:</b> <a href="mailto:seansaliva@att.net">seansaliva@att.net</a>
      </p>
    </div>
  );
};

export default Contact;
