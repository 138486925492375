import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import "../components/photos.css";

const itemData = [
    {
        img: '../images/photography/air_show.jpg',
        title: 'Air Force Thunderbird - Fort Lauderdale Air Show',
    }, 
    {
        img: '../images/photography/air-show-photography-fort-lauderdale.jpg',
        title: 'Fort Lauderdale Air Show',
    },      
    {
        img: '../images/photography/bride-groom.png',
        title: 'Miami Wedding Photography',
    }, 
    {
        img: '../images/photography/food.png',
        title: 'Fusion Chef Chicken and Rice Dinner',
    },  
    {
        img: '../images/photography/img4.jpg',
        title: 'Sunrise with Seagull Flying in the Foreground',
    },  
    {
        img: '../images/photography/img5.jpg',
        title: 'Epic Sunrise on Fort Lauderdale Beach',
    },    
    {
        img: '../images/photography/lower-banner.jpg',
        title: 'Night Shot of Miami Skyline',
    },  
    {
        img: '../images/photography/nico.jpg',
        title: 'Nico on the Bench Soccer Game',
    },   
    {
        img: '../images/photography/south-florida-food-photography-davie.jpg',
        title: 'Miami Wedding Reception',
    },  
    {
        img: '../images/photography/wedding-photo.png',
        title: 'Miami Wedding Bride leaving Car',
    },  
    {
        img: '../images/photography/wedding-photography-miami-reception-photos.jpg',
        title: 'Miami Wedding Reception',
    },
    {
        img: '../images/photography/wedding-photos-dade-county-miami.jpg',
        title: 'Miami Wedding Reception Bride Groom',
    }, 
];

const Photos = () => {
  return (
    <Box sx={{ width: 1630, height: 850, overflowY: 'scroll' }}>
    <ImageList className="img-fluid thumbnail" variant="masonry" cols={3} gap={30}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  </Box>
  );
}

export default Photos;