import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Row, Col } from "react-bootstrap";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "../components/resumeInfo.css";

const AccordionRes = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>General Information</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="P-3">
          <Typography>
            <Row>
              <Col>
                <h2 className="mb-0">Sean O. Saliva</h2>
                <p className="my-0">Sr. UI/UX Designer & Developer</p>
                <p className="my-0">Sr. Graphic Designer</p>
                <p>Sr. Front-End Developer</p>
              </Col>
              <Col>
                <h2>Contact Info</h2>
                <p className="mb-0">Phone: 954.709.7709</p>
                <p className="my-0">
                  Email:
                  <a href="mailto:seansaliva@att.net">seansaliva@att.net</a>
                </p>
              </Col>
            </Row>
            <hr />
            <Row>
              <h3>Objective</h3>
              <p>
                To obtain employment with a respectable, stable firm with the
                expectation that my positive mental demeanor, super quick
                learning capabilities, and remarkable relationship building
                abilities will help impact the firms overall productivity.
              </p>
            </Row>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>Skills</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Row>
              <Col>
                <ul>
                  <li>CSS</li>
                  <li>LESS & SASS</li>
                  <li>HTML5</li>
                  <li>MySQL</li>
                  <li>PHP</li>
                  <li>Bootstrap</li>
                  <li>Bootstrap React</li>
                  <li>JQuery</li>
                  <li>Javascript</li>
                  <li>React</li>
                  <li>Materials-UI</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>Node</li>
                  <li>Responsive Design</li>
                  <li>Front-End Dev</li>
                  <li>UI/UX Design</li>
                  <li>UI/UX Development</li>
                  <li>Abode Illustrator</li>
                  <li>Adobe Photoshop</li>
                  <li>Adobe XD</li>
                  <li>Adobe InDesign</li>
                  <li>Adobe After Effects</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>Adobe Premier Pro</li>
                  <li>Figma</li>
                  <li>InVision</li>
                  <li>Visual Studio Code</li>
                  <li>GIT</li>
                  <li>Jira</li>
                  <li>SVN</li>
                  <li>Google Analytics</li>
                  <li>Workfront</li>
                  <li>Excel</li>
                  <li>PowerPoint</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>Access</li>
                  <li>Word</li>
                  <li>Cinema 4D</li>
                  <li>Photography</li>
                  <li>Digital &amp; Print</li>
                  <li>Sitecore CMS</li>
                  <li>Sitefinity CMS</li>
                  <li>Wordpress</li>
                  <li>Critical Thinking</li>
                  <li>Problem Solvers</li>
                  <li>Research Skills</li>
                </ul>
              </Col>
            </Row>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>Experience</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <hr />
            <Row>
              <Col>
                <p className="h4">Life Extension Buyers Club, Inc</p>
                <p>
                  <strong>UI/UX Design &amp; Developer</strong> - July 2019 to
                  Nov 2022
                </p>
                <p>
                  Responsibilities as a full-time UI/UX Designer and Developer:
                  creating websites mock ups for improvements, landing pages and
                  new modules using Adobe XD, Development using HTML/CSS/
                  Bootsrap 4/jQuery/Javascript within the Sitecore CMS.
                </p>
                <p>
                  <strong>Environment:</strong> Photoshop, Illustrator, Adobe
                  XD, HTML, CSS, jQuery, Bootstrap, Javascript
                </p>
              </Col>
              <Col>
                <p className="h4">4 Media Online - Online Media Company</p>
                <p>
                  <strong>Front End Web Developer / Graphic Design</strong> -
                  Oct 2016 to Mar 2019
                </p>
                <p>
                  Responsibilities as a full-time Front End Developer creating
                  websites, using HTML/CSS/Bootsrap/jQuery/ Javascript,
                  Photoshop, Illustrator and GIT. Create sites from concept,
                  visualization and development for company commerical dating
                  sites. Over 220+ hand-coded responsive websites.
                </p>
                <p>
                  <strong>Environment:</strong> Photoshop, Illustrator, Adobe
                  Premiere, After Effects, HTML, CSS, jQuery, Bootstrap,
                  Javascript
                </p>
              </Col>
              <Col>
                <p className="h4">Time4Learning.com - CONTRACT</p>
                <p>
                  <strong>Web Designer & Developer / Graphic Design</strong> -
                  Apr 2014 to Feb 2015
                </p>
                <p>
                  Worked on team that developed website, integrating several new
                  technologies, such as responsive design using bootstrap and
                  media queries, Video Portal Design and Develop- ment and daily
                  maintenance to 6 existing website, time4learning.com,
                  time4writing.com science4us.com, VocabularySpellingCity.com,
                  learninggamesforkids. com, www. vocabulary.co.il
                </p>
                <p>
                  <strong>Environment:</strong> PC, HTML5, CSS3, Bootstrap,
                  Media Queries, Jquery, Javascript,
                </p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <p className="h4">Investacorp, Inc - Financial Broker/Dealer</p>
                <p>
                  <strong>Front End Developer &amp; Graphic Designer</strong> -
                  Nov 2010 to Apr 2014
                </p>
                <p>
                  Create, update, and maintain site pages for investalink.com a
                  complex and robust web application for Investacorp’s Financial
                  Advisors. Charged with UI/UX Design & Development and overhaul
                  of site, Front End Development, Mobile Development, Statements
                  Styles, Financial Report Styles and Creation as well as
                  Document Styles and Creation, Marketing Materials, Logos,
                  Brochures and all Video and Media requirements.
                </p>
                <p>
                  <strong>Environment:</strong> PC & MAC, HTML5, CSS3, Jquery,
                  Javascript, Photoshop, Illustrator, Mock Ups, Wireframes,
                  UI/UX Design & Development, Premier Pro, After Eﬀects, Cinema
                  4D, Flash, Motion, Compressor, SVN, Eclipse, InDesign,
                  Storyboards, Video Production, Video Editing, Motion Graphics
                  & Print Graphics. Responsive Design, Photoshop, Illustrator,
                  Mock Ups, Wireframes, Premier Pro, After Effects
                </p>
              </Col>
              <Col md={6}>
                <p className="h4">McNiel Signs, Inc - Sign Co.</p>
                <p>
                  <strong>Graphic Designer &amp; Vinyl Production</strong> - Oct
                  2009 to Oct 2010
                </p>
                <p>
                  Lead Graphic Designer for custom signs and vinyl production.
                  Utilized contemporary design to create concise signage for
                  specific client needs. Sign creation called for the use of
                  Adobe Illustrator, Coral Draw and Vinyl Cutting Software and
                  Tools.
                </p>
                <p>
                  <strong>Environment:</strong> Photoshop, Illustrator, Corel
                  Paint
                </p>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>Education</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <h4 className="fw-bold mb-0">Keiser University Online</h4>
            <p className="my-0">
              Bachelors of Art Political Science - Class of 2021
            </p>
            <p className="fw-bold my-0">
              <em>Valedictorian - Summa Cum Laude - Perfect 4.0</em>
            </p>
            <br />
            <h4 className="fw-bold mb-0">
              The Art Institute of Fort Lauderdale
            </h4>
            <p className="my-0">Major—Interactive Media Design</p>
            <br />
            <h4 className="fw-bold mb-0">Dillard High School</h4>
            <p className="my-0">Academics – 4 Years – Class of 1994</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>Organizations</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p className="fw-bold mb-0">PHI THETA KAPPA</p>
            <p className="my-0">National Honor Society - Member Since 2020</p>
            <br />
            <p className="fw-bold mb-0">
              NSLS - National Society of Leadership &amp; Success
            </p>
            <p className="my-0">National Honor Society - Member Since 2022</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>Salary Requirements</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <h4 className="fw-bold">$95,000 - $125,000 (negotiable)</h4>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            <h3>Social Media</h3>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <LinkedInIcon />
              <a href="https://www.linkedin.com/in/sean-saliva/">
                LinkedIn Page
              </a>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionRes;
