import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const itemData = [
    {
        img: "../images/graphic-design/Autism_Speaks_Ad_v5.0.png",
        title: 'Autisim Speaks Ad',
    },
    {
        img: "../images/graphic-design/dhsaba.png",
        title: 'DHS Alumni Band Association',
    },
    {
        img: "../images/graphic-design/endCard.jpg",
        title: 'Time 4 Writing Video EndCard',
    },
    {
        img: "../images/graphic-design/GOBO_LOGO-1024x576.jpg",
        title: 'Investacorp Conference Logo',
    },
    {
        img: "../images/graphic-design/graphic-design-south-florida-advertisement.png",
        title: 'Time 4 Learning Magazine Ad',
    },
    {
        img: "../images/graphic-design/icorp3dlogo.png",
        title: 'Investacorp 3D Logo',
    },
    {
        img: "../images/graphic-design/instagram.png",
        title: 'Big Daddy Smokers IG Ad',
    },
    {
        img: "../images/graphic-design/Investalink_LAYOUT3-1024x768.png",
        title: 'Investalink Mock Up Concept',
    },
    {
        img: "../images/graphic-design/Mock_Up_T4L_Video_Testimonials_Page_OPT_G-1024x555.png",
        title: 'Time 4 Learning Mock Up',
    },
    {
        img: "../images/graphic-design/pr_logo_shirt.png",
        title: 'PR Conference Logo',
    },
    {
        img: "../images/graphic-design/Signage_Exhibits-683x1024.jpg",
        title: 'Conference_Signage_Exhibit',
    },
    {
        img: "../images/graphic-design/signage-graphic-design.jpg",
        title: 'Conference_Signage_Exhibit II',
    },
    {
        img: "../images/graphic-design/time_4_learning_ad.jpg",
        title: 'Time 4 Learning Ad',
    },
    {
        img: "../images/graphic-design/Tucson-Logo.jpg",
        title: 'Tucson Conference Logo',
    },
    {
        img: "../images/graphic-design/Tucson-Logo_2.jpg",
        title: 'Alternative Tucson Conference Logo',
    },
    {
        img: "../images/graphic-design/TY-Logo.png",
        title: 'Tayana Campos Realtor Logo',
    },

  ];

const Graphics = () => {
  return (
    <div>
    <ImageList
    sx={{ width: 1600, height: 838 }}
    variant="quilted"
    cols={4}
    rowHeight={450}
  >
    {itemData.map((item) => (
      <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
        <img
          {...srcset(item.img, 121, item.rows, item.cols)}
          alt={item.title}
          loading="lazy"
        />
      </ImageListItem>
    ))}
  </ImageList>
    </div>
  );
}

export default Graphics;
