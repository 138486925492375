import { Container, Row, Col } from "react-bootstrap";
import BetaPhiDelta from "../docs/Beta-Phi-Delta-Foundation.pdf";
import BodyTrim from "../docs/Body-Trim-LP.pdf";
import Info from "../docs/Infographic-to-web.pdf";
import OChris from "../docs/O-Chris-McLemore-Funeral-Homes.pdf";
import Palmetto from "../docs/palmetto-express-homepage.pdf";

const UIUX = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h2>Latest Projects</h2>
            <ul>
              <li>
                <a
                  href={BetaPhiDelta}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Beta Phi Delta Foundation
                </a>
              </li>
              <li>
                <a
                  href={BodyTrim}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Life Extension Body Trim Landing Page
                </a>
              </li>
              <li>
                <a
                  href={Info}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Infographic to Webpage Mock-Up
                </a>
              </li>
              <li>
                <a
                  href={OChris}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  O. Chirs McLemore Funeral Homes Web Site Mock-Up
                </a>
              </li>
              <li>
                <a
                  href={Palmetto}
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Palmetto Expressway Homepage Redesign Mock-Up
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UIUX;
