import FrontEndSites from "../components/FrontEndSites";
import "../pages/homepage.css"

const FrontEnd = () => {
  return (
    <div className='mainContainer'>
        <h1>FRONT END DEVELOPMENT</h1>
        <hr />
        <FrontEndSites/>
    </div>
  );
}

export default FrontEnd;