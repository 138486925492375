import { Row, Col } from "react-bootstrap";
import "../pages/about.css";

const About = () => {
  return (
    <div class="mainContainer">
      <Row>
        <Col lg="5">
           <img src="../images/sean-behind-the-camera.jpg" className="img-fluid img-fix" alt="Sean behind the Camera in Puerto Rico Investacorp Conference"/>
        </Col>
        <Col lg="7" className="aboutText">
          <h1>About Sean O. Saliva</h1>
          <hr />
          <p>Sean Saliva was born in Brooklyn, N.Y. in 1976. He grew up and lived in Brooklyn until he was 14 years old and his family relocated to South Florida. In South Florida, Sean attended Everglades Middle School, graduated from Dillard High School in 1994, and graduated Summa Cum Laude from Kieser University in 2022 with a bachelor's Degree in Political Science. Sean was recognized as one of six valedictorians for the graduating Class of 2022, earning his degree with the highest distinction and a perfect 4.0gpa during his academic career.
          </p>
          
          <p>Professionally, Sean has worked in one form or another in the interactive media realm since 2005. Holding positions as a graphic designer, web designer, web developer, front-end developer, UI/UX Designer, UI/UX Developer, videographer, motion graphic designer, and photographer. As of late, Sean has been focused on building his hands-on experience with Full Stack Development working with React, Node, Express, and MySQL, while also learning more about the specifics of the LAMP and MERN development stacks.</p>
        
          <p>In his personal life, Sean is a single father of two amazing young men, Sean Saliva Jr. 18, and his youngest Nicholas Saliva 14. He has raised them both on his own since the boys were 8 and 5. It has been the most challenging and rewarding achievement of his life. Sean enjoys baseball, football, wrestling, politics, civics, history, and international affairs, and hopes to continue his schooling, working towards a Master's Degree in International Relations with hopes of entering the foreign policy space as a public servant as soon as his children are done with schooling, and are chasing their own dreams in life.</p>
        
          <p>Sean is confident, professional, enthusiastic, efficient, and passionate about his work. He is easy to work with, reserved, yet never afraid to contribute to the conversation, so the best product is delivered at the end of any project. He always aims to deliver on time and beyond expectations, taking his 20+ years of experience, current trends, and technological realities into account while working through project requirements, so he can deliver as promised.</p>
        
          <p>If you are considering Sean for a full-time position, or a current freelance project, rest assured you are getting a professional, polite, responsible, dedicated partner who will work with you to help you achieve your business goals and projects. Please take a moment to look through Sean's portfolio and don't forget to look through and download his current resume. Thank you in advance for the opportunity to serve you.</p>
        </Col>
      </Row>
    </div>
  );
}

export default About;
