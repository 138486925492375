import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "../pages/homepage.css";
import AccordionRes from "../components/AccordionRes";

const Resume = () => {
  return (
    <div class="mainContainer">
      <h1>SEAN O SALIVA'S RESUME</h1>
      <hr />
      <span className="d-flex align-item-center">
        PDF Version:&nbsp;
        <a href="/resumes/SeanSalivaResume_2022.pdf" download>
          <PictureAsPdfIcon className="pdfIcon" />
        </a>
      </span>
      <br />
      <AccordionRes />
    </div>
  );
};

export default Resume;
