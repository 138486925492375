import { Row, Col } from "react-bootstrap";
import "../components/frontEndSites.css";

const FrontEndSites = () => {
  return (
    <div>
      <Row className="text-center">
        <Col>
          <a
            href="http://www.betaphideltafoundation.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="../images/front-end/beta-phi-delta-foundation.png"
              className="img-fluid img-thumbnail"
              alt="The Beta Phi Delta Foundation"
            />
            <h2 className="mt-3">The Beta Phi Delta Foundation</h2>
            <p>www.betaphideltafoundation.com</p>
          </a>
        </Col>
        <Col>
          <a
            href="https://www.lifeextension.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="../images/front-end/life-extension.png"
              className="img-fluid img-thumbnail"
              alt="Life Extension Vitamins and Supplements"
            />
            <h2 className="mt-3">Life Extension, Inc</h2>
            <p>www.lifeextension.com</p>
          </a>
        </Col>
        <Col>
          <a href="http://www.826palmetto.com" target="_blank" rel="noreferrer">
            <img
              src="../images/front-end/fdot-palmetto-express-lanes.png"
              className="img-fluid img-thumbnail"
              alt="Southeast Florida Transportation Council"
            />
            <h2 className="mt-3">FDOT Palmetto Express Lanes</h2>
            <p>www.826Palmetto.com</p>
          </a>
        </Col>
        <Col>
          <a
            href="http://www.movefloridaforward.org"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="../images/front-end/move-florida-forward.png"
              className="img-fluid img-thumbnail"
              alt="Southeast Florida Transportation Council"
            />
            <h2 className="mt-3">Southeast Florida Transportation Council</h2>
            <p>www.movefloridaforward.org</p>
          </a>
        </Col>
      </Row>
      <Row className="text-center mt-5 pt-5">
        <Col>
          <a
            href="https://www.fdot.gov/agencyresources/be-rail-smart/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="../images/front-end/be-rail-smart.png"
              className="img-fluid img-thumbnail"
              alt="FDOT Be Rail Smart Campaign"
            />
            <h2 className="mt-3">FDOT Be Rail Smart Campaign</h2>
            <p>www.fdot.gov/agencyresources/be-rail-smart</p>
          </a>
        </Col>
        <Col>
          <a
            href="http://www.ejtamayoracing.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="../images/front-end/ej-tamayo-racing.png"
              className="img-fluid img-thumbnail"
              alt="EJ Tamayo Racing Website"
            />
            <h2 className="mt-3">EJ Tamayo Racing</h2>
            <p>www.ejtamayoracing.com</p>
          </a>
        </Col>
        <Col>
          <img
            src="../images/front-end/intense-love-finder.png"
            className="img-fluid img-thumbnail"
            alt="Intense Love Finder Dating Site"
          />
          <h2 className="mt-3">Intense Love Finder Dating Site</h2>
          <p>www.intenselovefinder.com</p>
        </Col>
        <Col>
          <img
            src="../images/front-end/blurred-lines-marketing.png"
            className="img-fluid img-thumbnail"
            alt="Blurred Lines Marketing"
          />
          <h2 className="mt-3">Blurred Lines Marketing</h2>
          <p>www.blurredlinesmarketing.com</p>
        </Col>
      </Row>
    </div>
  );
};

export default FrontEndSites;
