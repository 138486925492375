import Photos from "../components/Photos";
import "../pages/homepage.css"

const Photo = () => {
  return (
    <div className='mainContainer'>
        <h1>PHOTOGRAPHY PORTFOLIO</h1>
        <hr />
        <Photos/>
    </div>
  );
}

export default Photo;