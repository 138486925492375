import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../pages/homepage.css";

function Homepage() {
  return (
    <div className="mainContainer">
      <div className="jumbotron">
        <div className="overlay">
          <h1 className="text-white">Sean O. Saliva</h1>
          <h2 className="h3 text-white">
            Front End Web Developer - UI/UX Developer <br /> Full Stack Web
            Development - Interactive Media Design
          </h2>
          <p>
            Freelancer, honest, reliable, and efficient!!
            <br /> Designing and Developing web sites for over 20 years! Give me
            a call today.
          </p>
        </div>
      </div>
      <Container fluid className="mt-5">
        <Row>
          <Col xs={12} md={6} lg={3}>
            <Card className="Card">
              <Card.Img variant="top" src="../images/sean_saliva.jpg" />
              <Card.Body>
                <Card.Title>About Sean</Card.Title>
                <Card.Text>
                  Discover the brilliance behind Sean's grounded perspective and
                  approach to life.
                </Card.Text>
                <Link to="/About" className="btn btn-primary" variant="primary">
                  Learn More
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="Card">
              <Card.Img variant="top" src="../images/portfolio.jpg" />
              <Card.Body>
                <Card.Title>Portfolio</Card.Title>
                <Card.Text>
                  Explore the creativity and innovation behind my work. Click
                  the portfolio link below.
                </Card.Text>
                <Link
                  to="/Portfolio"
                  className="btn btn-primary"
                  variant="primary"
                >
                  Learn More
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="Card">
              <Card.Img variant="top" src="../images/resume.jpg" />
              <Card.Body>
                <Card.Title>Resume</Card.Title>
                <Card.Text>
                  Hire a standout candidate with a proven track record. Click
                  the resume link below.
                </Card.Text>
                <Link
                  to="/Resume"
                  className="btn btn-primary"
                  variant="primary"
                >
                  Learn More
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="Card">
              <Card.Img variant="top" src="../images/blog.jpg" />
              <Card.Body>
                <Card.Title>Blog</Card.Title>
                <Card.Text>
                  Get a glimpse into the mind of a top candidate. Click the blog
                  link to see my ideas in action.
                </Card.Text>
                <Link to="/Blog" className="btn btn-primary" variant="primary">
                  Learn More
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Homepage;
