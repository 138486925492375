import UIUX from "../components/UIUX";
import "../pages/homepage.css"

const UIUXDesign = () => {
  return (
    <div className='mainContainer'>
        <h1>UI/UX Design</h1>
        <hr />
        <UIUX/>
    </div>
  );
}

export default UIUXDesign;