import Graphics from "../components/Graphics";
import "../pages/homepage.css"

const GraphicDesign = () => {
  return (
    <div className='mainContainer'>
        <h1>GRAPHIC DESIGN</h1>
        <hr />
        <Graphics/>
    </div>
  )
}

export default GraphicDesign;