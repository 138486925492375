import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Skillset = () => {
  return (
    <div className="mt-4">
      <h2>The Skillset</h2>
      <hr />
      <Row>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>CSS:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>LESS &amp; SASS:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>HTML5:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>MySQL:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={75} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>PHP:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={50} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>BOOTSTRAP:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>JQUERY:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={80} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>JAVASCRIPT:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={60} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>REACT:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={50} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>NODE:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={20} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>UI/UX DESIGN:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>FRONT END:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>PHOTOSHOP:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>ILLUSTRATOR:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>ADOBE XD:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>INDESIGN:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>AFTER EFFECTS:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>PREMIER PRO:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>INVISION:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={60} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>V.S. CODE:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>GIT:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>JIRA:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>SVN:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={50} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>ANALYTICS:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={80} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>WORKFRONT:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={80} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>MS OFFICE:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={4}>
              <strong>CINEMA 4D:</strong>
            </Col>
            <Col md={8}>
              <BorderLinearProgress variant="determinate" value={70} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>DIGITAL &amp; PRINT:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>SITECORE CMS:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>SITEFINITY CMS:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={80} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>WORDPRESS:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={95} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>CRITICAL THINKER:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>PROBLEM SOLVER:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>RESEARCH:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={5}>
              <strong>LEADERSHIP:</strong>
            </Col>
            <Col md={7}>
              <BorderLinearProgress variant="determinate" value={100} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Skillset;
