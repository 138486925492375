import Videos from "../components/Videos";
import "../pages/homepage.css";

const Video = () => {
  return (
    <div className='mainContainer'>
        <h1>VIDEO PORTFOLIO</h1>
        <hr />
        <Videos/>
    </div>
  )
}

export default Video;