import { Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skillset from "../components/Skillset";
import "../pages/homepage.css";
import "../pages/portfolio.css";

const Portfolio = () => {
  return (
    <div className="mainContainer">
      <h1>PORTFOLIO</h1>
      <hr />
      <Row className="mt-4">
        <Col>
          <Card className="Card">
            <Card.Img variant="top" src="../images/UI-UX-Banner.png" />
            <Card.Body>
              <Card.Title>UI/UX Design</Card.Title>
              <Card.Text>
                Enter the UI/UX portion of my website to see how I create
                seamless and intuitive designs.
              </Card.Text>
              <Link
                to="/UIUXDesign"
                className="btn btn-primary"
                variant="primary"
              >
                UI/UX Portfolio
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="Card">
            <Card.Img variant="top" src="../images/front-end-sites.png" />
            <Card.Body>
              <Card.Title>Front End Development</Card.Title>
              <Card.Text>
                Enter the front-end development section of my website to see how
                I turn ideas into stunning realities.
              </Card.Text>
              <Link
                to="/FrontEnd"
                className="btn btn-primary"
                variant="primary"
              >
                Front-End Portfolio
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="Card">
            <Card.Img variant="top" src="../images/graphic-design-banner.png" />
            <Card.Body>
              <Card.Title>Graphic Design</Card.Title>
              <Card.Text>
                Enter the graphic design section to see how I bring your ideas
                to life with color, form and typography.
              </Card.Text>
              <Link
                to="/GraphicDesign"
                className="btn btn-primary"
                variant="primary"
              >
                Graphic Design Portfolio
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="Card">
            <Card.Img variant="top" src="../images/video-banner.png" />
            <Card.Body>
              <Card.Title>Video &amp; Motion Graphics</Card.Title>
              <Card.Text>
                Enter the video & motion graphics section of my website to see
                how I craft engaging visual experiences.
              </Card.Text>
              <Link to="/Video" className="btn btn-primary" variant="primary">
                Videography Portfolio
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="Card">
            <Card.Img variant="top" src="../images/photo-banner.png" />
            <Card.Body>
              <Card.Title>Photography</Card.Title>
              <Card.Text>
                Enter the photography section of my website to see how I turn
                moments into memories.
              </Card.Text>
              <Link to="/Photo" className="btn btn-primary" variant="primary">
                Photography Portfolio
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Skillset />
      </Row>
    </div>
  );
};

export default Portfolio;
