import { Row, Col } from "react-bootstrap";
import "../components/videos.css";

const Videos = () => {
  return (
        <div className="VideoContain">
        <Row>
            <Col>
            <iframe width="100%" height="275px" src="https://www.youtube.com/embed/0bPLeGTaAXY?controls=0" frameborder="0" allowfullscreen="" title="Covenant Mortuary"></iframe>
            </Col>
            <Col>
            <iframe width="100%" height="275px" src="https://www.youtube.com/embed/cnIze-n5MR4?controls=0" frameborder="0" allowfullscreen="" title="2013 Investacorp National Conference"></iframe>
            </Col>
            <Col>
            <iframe width="98%" height="275px" src="https://www.youtube.com/embed/N_31mPIT-_U?controls=0" frameborder="0" allowfullscreen="" title="Ladenburg Thalman Women in Finance Conference"></iframe>
            </Col>
        </Row>
        <Row className="my-3">
            <Col>
            <iframe width="100%" height="275px" src="https://www.youtube.com/embed/7dqC1Nz8mzQ?controls=0" frameborder="0" allowfullscreen="" title="SAAR MFG Gun Shop Video"></iframe>
            </Col>
            <Col>
            <iframe width="100%" height="275px" src="https://www.youtube.com/embed/NxWml88vtyk?controls=0" frameborder="0" allowfullscreen="" title="Think and Drive Campaign FDOT"></iframe>
            </Col>
            <Col>
            <iframe width="98%" height="275px" src="https://www.youtube.com/embed/Qpuu6Zc3vOo?controls=0" frameborder="0" allowfullscreen="" title="SJN Media Group"></iframe>
            </Col>
        </Row>
        <Row>
            <Col>
            <iframe width="100%" height="275px" src="https://www.youtube.com/embed/mCjbxqfNyo4?controls=0" frameborder="0" allowfullscreen="" title="Conference Logo in 3D"></iframe>
            </Col>
            <Col>
            <iframe width="100%" height="275px" src="https://www.youtube.com/embed/CaHPytTVeHI?controls=0" frameborder="0" allowfullscreen="" title="SJN Films Logo Reeal"></iframe>
            </Col>
            <Col>
            <iframe width="98%" height="275px" src="https://www.youtube.com/embed/hKKPWbUwgJg?controls=0" frameborder="0" allowfullscreen="" title="SJN Films - Investacorp Testimonial"></iframe>    
            </Col>
        </Row>
        </div>
  );
}

export default Videos;
