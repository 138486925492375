import React from "react";

const Blog = () => {
  return (
    <div class="mainContainer">
      <h1>BLOG PAGE COMING SOON</h1>
    </div>
  );
};

export default Blog;
