import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import TopicIcon from "@mui/icons-material/Topic";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import BookIcon from "@mui/icons-material/Book";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import "../components/sidebar.css";

const Sidebar = () => {
  return (
    <div>
      <Navbar className="sidebar" bg="bg-light" expand="lg">
        <Container>
          <img
            src="../images/profile-img.png"
            className="img-fluid profile"
            alt="Sean O. Saliva"
          />
          <Navbar.Brand href="#home" className="text-white fw-bold logo">
            Sean O. Saliva
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto bg-sidebar">
              <Link className="mb-4 test" exact to="/">
                <HomeIcon className="me-2" />
                Home
              </Link>
              <Link className="mb-4 test" to="/About">
                <AccountCircleIcon className="me-2" />
                About
              </Link>
              <Link className="mb-4 test" to="/Portfolio">
                <TopicIcon className="me-2" />
                Portolio
              </Link>
              <Link className="mb-4 test" to="/Resume">
                <DescriptionIcon className="me-2" />
                Resume
              </Link>
              <Link className="mb-4 test" to="/Blog">
                <BookIcon className="me-2" />
                Blog
              </Link>
              <Link className="mb-4 test" to="/Contact">
                <MobileScreenShareIcon className="me-2" />
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Sidebar;
